<template>
  <PageWithLayout>
    <Search
      :tabData.sync="viewModel.tabData"
      :searchDataList.sync="viewModel.searchData[viewModel.tabData.value].searchDataList"
      @onSearch="viewModel.onSearch()"/>
    <!-- <div class="box_search_result">
      <p>윤엄마, 010-1234-1234로 신청된 신청서 내역</p>
    </div> -->
    <div class="list_card">
      <CardApplyItem
        v-for="(item,index) in viewModel.dataList"
        :key="`cardApplyItem${index}`"
        :boardData="viewModel.boardData"
        :rowData="item"/>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import CardApplyItem from '@/views/service/card/view/CardApplyItem';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
// viewModel
import CardApplyListViewModel from '@/views/service/card/viewModel/CardApplyListViewModel'

export default {
  name:'CardApplyList',
  components:{
    PageWithLayout,
    Search,
    CardApplyItem,
  },
  data(){
    return{
      viewModel: new CardApplyListViewModel(),
    }
  },
}
</script>

<style scoped>
.box_search_result{padding:10px 20px;border-top:1px solid #222;border-bottom:1px solid #ccc;background-color:#fff}
</style>